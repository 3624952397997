.gallery-container{
    width: 100%;
}
.gallery-main-container{
    margin-left: 110px;
    margin-right: 110px;
      margin-bottom: 30px;
}
.gallery-image{
    height: 350px;
    width: 630px;
}
.gallery-image-container{
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    margin-top: 20px;
    justify-content: center;
}


@media only screen and (max-width: 450px) {

.gallery-image {
    height: 350px;
    width: 350px;
}
.gallery-main-container {
    margin-left: 0px;
     margin-right: 0px;
    /* display: inline-block; */
    margin-bottom: 30px;
}
}