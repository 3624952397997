.program-container{
    width: 100%;
}
.program-main-container{
    margin-left: 110px;
    margin-right: 110px;
      margin-bottom: 30px;
}
.event-image{
    height: 300px;
    width: 620px;
}
.event-container{
    display: flex;
    /* flex-direction: column; */
    gap: 130px;
    margin-top: 20px;
}
.event-content{
    /* display: flex; */
    gap: 30px;
    width: 50%;
}
.event-content-p{
    color: gray;
    font-size: 20px;
    text-align: justify;
    font-family: sans-serif;
    margin-top: 20px;
}
@media only screen and (max-width: 450px) {
    .program-main-container {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
    }
    .event-image {
        height: 280px;
    width: 350px;
    }
    .event-content-p {
        color: gray;
        font-size: 20px;
        text-align: justify;
        font-family: sans-serif;
        margin-top: 20px;
        width: 350px;
    }    
    .event-container{
        flex-direction: column;
        gap: 0px;
    }
  }


  @media only screen and (max-width: 900px) and (min-width:450px) {
    .program-main-container {
        margin-left: 75px;
        margin-right: 75px;
        /* width: 100%; */
        /* display: inline-block; */
        margin-bottom: 30px;
    }
    .event-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 20px;
    }
    .event-content-p {
        color: gray;
        font-size: 20px;
        text-align: justify;
        font-family: sans-serif;
        margin-top: 20px;
        width: 621px;
    }
  }
  @media only screen and (max-width: 1100px) and (min-width:900px) {
    .program-main-container {
        margin-left: 75px;
        margin-right: 75px;
        /* width: 100%; */
        /* display: inline-block; */
        margin-bottom: 30px;
    }
    .event-image {
        height: 300px;
        width: 400px;
    }
    .event-container {
        display: flex;
        /* flex-direction: column; */
        gap: 80px;
        margin-top: 20px;
    }
    /* .event-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 20px;
    }
    .event-content-p {
        color: gray;
        font-size: 20px;
        text-align: justify;
        font-family: sans-serif;
        margin-top: 20px;
        width: 621px;
    } */
  }
