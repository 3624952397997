.first-navbar {
  background-color: #fff;
  height: 110px;
  width: 100%;
}
.first-navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 100%;
  /* margin-left: 110px; */
  /* margin-right: 110px; */
}
.logo {
  height: 85px;
}
.header-heading {
  font-size: 55px;
  margin-top: -10px;
  font-weight: bold;
  color: #5c5959;
  letter-spacing: 4px;
  height: 10px;
}
.header-pera {
  font-size: 35px;
  font-weight: bold;
  color: #002f56;
  line-height: 0px;
  letter-spacing: 2px;
  margin-top: 78px;
  text-align: center;

}
.second-navbar {
  width: 100%;
  height: 60px;
  background-color: #0d374c;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-ul {
  display: flex;
  list-style: none;
  color: #ccc;
  gap: 20px;
  cursor: pointer;
}
.header-ul li:hover {
  color: #ffd332;
}
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #002542;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #ccc;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #6bd1e8;
  color: #ffd332;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.hamburgar-box {
  display: none;
}
.nav-content1 {
  display: none;
}
.sidebar-ul {
  display: flex;
  list-style: none;
  gap: 25px;
  margin-right: 40px;
  cursor: pointer;
  flex-direction: column;
  font: normal normal 600 14px/20px Source Sans Pro;
  letter-spacing: 0.56px;
  color: #ffd332;
}
.link{
  text-decoration: none;
  color: #ccc;
}
.logo2{
  display: none;
}
@media only screen and (max-width: 450px) {
  .first-navbar {
    height: 82px;
  }
  .first-navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    /* margin-left: 10px; */
    margin-right: 10px;
  }
  .logo {
    height: 50px;
    margin-left: 10px;
display: none;
  }
  .logo2{
display: block;
height: 50px;
    margin-left: 10px;
  }
  .logo1{
    display: none;
  }
  .header-heading {
    font-size: 25px;
    margin-top: 0px;
    font-weight: bold;
    /* color: yellow; */
    letter-spacing: 0px;
    height: 11px;
    line-height: 30px;

  }
  .header-pera {
    font-size: 18px;
    font-weight: bold;
    /* color: white; */
    line-height: 0px;
    letter-spacing: 0px;
    margin-top: 62px;
    text-align: left;
  }
  .second-navbar {
    display: none;
  }
  .hamburgar-box {
    display: block;
    margin-right: 20px;
  }
  .sidebar {
    width: 250px;
    height: 100vh;
    background-color: #1f2a5f;
    float: right;
    display: block;
    position: absolute;
    z-index: 99;
    top: 82px;
  }
  .carousel-image {
    height: 230px;
  }
}

@media only screen and (max-width: 900px) and (min-width: 450px)  {
  .first-navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    width: 100%;
    margin-left: 0px;
    margin-right: 25px;
  }
  .header-heading {
    font-size: 45px;
    margin-top: 5px;
    font-weight: bold;
    color: #5c5959;
    letter-spacing: 5px;
    height: 7px;
    line-height: 36px;
}
.header-pera {
  font-size: 25px;
  font-weight: bold;
  color: #002f56;
  line-height: 0px;
  letter-spacing: 2px;
  margin-top: 79px;
  text-align: left;
}
/* .hamburgar-box{
  display: block;
} */
.logo{
  margin-left: 25px;
}
}
@media only screen and (max-width: 1100px) and (min-width: 900px)  {
  .first-navbar-container {
    width: 100%;
    margin-left: 0px;
    margin-right: 25px;
  }
  .header-heading {
    font-size: 45px;
    margin-top: 5px;
    font-weight: bold;
    color: #5c5959;
    letter-spacing: 5px;
    height: 7px;
    line-height: 36px;
}
.header-pera {
  font-size: 25px;
  font-weight: bold;
  color: #002f56;
  line-height: 0px;
  letter-spacing: 2px;
  margin-top: 79px;
  text-align: left;
}
/* .hamburgar-box{
  display: block;
} */
.logo{
  margin-left: 30px;
}
.first-navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 95px;

}
}
